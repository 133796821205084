<template>
  <div class="pt-2">
    <!-- Hide Prepayment option for now -->
    <div v-if="digitalPaymentMethods.length > 0" v-show="false">
      <ion-label class="text pl-2 fs-3 fw-600 text-gray-700">{{ labelDigitalPayment }}</ion-label>
      <ion-grid class="pt-3">
        <ion-radio-group :value="digitalCardIdSelected" @change="onSelectDigitalCard">
          <ion-row
            class="mb-2"
            v-for="method in digitalPaymentMethods"
            :key="method.id"
            @click="onSelectType(method.value, method.id)"
          >
            <ion-col size="auto" class="pt-2">
              <ion-img :src="method.image" />
            </ion-col>
            <ion-col class="d-flex align-center justify-space-between underline">
              <div>
                <ion-text class="text-gray-700 mb-1 fw-600">{{ method.name }}</ion-text>
                <div class="text-gray-500 mt-1 fs-2">{{ method.exp }}</div>
                <div
                  v-if="method.isDefault"
                  class="fs-1 fw-500 bg-primary text-center f-white bor-15 my-1 w-content px-2"
                >
                  {{ $t('default') }}
                </div>
              </div>
              <ion-radio v-if="isFullyPaidCustBalance" :disabled="true" class="mb-1"></ion-radio>
              <ion-radio v-else :value="method.id" class="mb-1"></ion-radio>
            </ion-col>
          </ion-row>
        </ion-radio-group>

        <ion-card
          class="bg-gray mt-5"
          v-if="isOpenFormNetsPayment && (isSingaporeUser || isMalaysianUser)"
          @click="$emit('addNetsBankCard')"
        >
          <ion-card-content class="px-2 py-2 d-flex justify-space-between align-center">
            <ion-icon color="primary" class="mr-2" :icon="cardOutline"></ion-icon>
            <ion-text class="flex-basis-90">{{ $t('accountPage.add_nets_card_type') }}</ion-text>
            <ion-icon color="primary" :icon="addCircleOutline" />
          </ion-card-content>
          <ion-text class="px-2 fs-1 py-2">
            {{ $t('this_will_be_added_to_all_user') }}
          </ion-text>
        </ion-card>
        <ion-card
          v-if="isSingaporeUser || isMalaysianUser"
          class="bg-gray"
          @click="$emit('setOpenModalAddCard')"
        >
          <ion-card-content class="px-2 py-2 d-flex justify-space-between align-center">
            <ion-icon color="primary" class="mr-2" :icon="cardOutline"></ion-icon>
            <ion-text class="flex-basis-90">{{ $t('add_card_or_credit_card') }}</ion-text>
            <ion-icon color="primary" :icon="addCircleOutline" />
          </ion-card-content>
        </ion-card>
      </ion-grid>
      <div class="spacer"></div>
    </div>

    <div>
      <ion-label class="text pl-2 fs-3 mb-3 fw-600 text-gray-700">{{ labelOtherPayment }}</ion-label>
      <ion-grid>
        <ion-radio-group v-model="paymentType">
          <ion-row
            class="mb-2 ion-align-items-center"
            v-for="method in otherPaymentMethods"
            :key="method.id"
            @click="onSelectType(method.value, method.id)"
          >
            <ion-col size="auto">
              <ion-img :src="method.image" />
            </ion-col>
            <ion-col class="d-flex align-center justify-space-between underline">
              <ion-text class="text-gray-800 mb-1">{{ $t(`${method.name}`) }}</ion-text>
              <ion-radio v-if="isFullyPaidCustBalance" :disabled="true" class="mb-1"></ion-radio>
              <ion-radio v-else :value="method.value" class="mb-1"></ion-radio>
            </ion-col>
          </ion-row>
        </ion-radio-group>
      </ion-grid>
    </div>
  </div>
</template>
<script>
import { CARD_IMAGE_BRAND, CARD_INVOICES_IMAGE, INVOICE_PAYMENT_TYPE } from '@/modules/shared/constants';
import { USER_COUNTRY } from '@/modules/shared/constants/';
import { useGetStorageData } from '@/usecases/';
import { addCircleOutline, cardOutline } from 'ionicons/icons';
import { defineComponent, onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
export default defineComponent({
  name: 'card-payment',
  props: {
    listCards: {
      type: Array,
      default: () => []
    },
    isOpenFormNetsPayment: {
      type: Boolean,
      default: false
    },
    isFullyPaidCustBalance: {
      type: Boolean,
      default: false
    }
  },
  emits: ['onChangePaymentType', 'setOpenModalAddCard', 'addNetsBankCard'],
  setup(props) {
    const { t } = useI18n();
    const { selectedUser } = useGetStorageData();
    const labelDigitalPayment = t('digital_payment_method');
    const labelOtherPayment = t('other_payment_method');
    const { BANK_TRANSFER, CREDIT_CARD, PAYNOW, CASH_DEPOSIT } = INVOICE_PAYMENT_TYPE;
    const digitalCardIdSelected = ref(null);
    const paymentType = ref(null);
    const isSingaporeUser = ref(false);
    const isMalaysianUser = ref(false);
    // const paynow = {
    //   // predefined payment method, therefore the id is hardcoded
    //   id: 99,
    //   brand: 'Paynow'
    // };
    // const mapDigitalCard = props.isSgUserOnly ? [...props.listCards, paynow] : [...props.listCards];
    const mapDigitalCard = [...props.listCards]; // TODO temporary remove paynow payment method per finance team request
    const digitalPaymentMethods = ref(
      mapDigitalCard.map((item, idx) => {
        return {
          id: item.id,
          name: item.brand,
          image: CARD_IMAGE_BRAND[item.brand.toUpperCase()],
          ...(item.id !== 99 ? { value: CREDIT_CARD } : { value: PAYNOW }),
          ...(item.id !== 99 && { exp: `*${item.last4} ${item.exp_month}/${item.exp_year}` }),
          isDefault: idx === 0
        };
      })
    );
    const otherPaymentMethods = ref([
      {
        id: 1,
        name: 'bank_transfer',
        value: BANK_TRANSFER,
        image: CARD_INVOICES_IMAGE.BANK_IMAGE
      },
      {
        id: 2,
        name: 'cash_deposit',
        value: CASH_DEPOSIT,
        image: CARD_INVOICES_IMAGE.CASH_DEPOSIT_IMAGE
      }
    ]);
    const onSelectType = (value, digitalId) => {
      if (props.isFullyPaidCustBalance) {
        return;
      }
      paymentType.value = value;
      digitalCardIdSelected.value = digitalId;
    };
    const onSelectDigitalCard = (value) => {
      if (props.isFullyPaidCustBalance) {
        return;
      }
      digitalCardIdSelected.value = value;
    };
    const checkUserCountry = async () => {
      const { country } = await selectedUser.value;
      if (!country) return;
      if (USER_COUNTRY.SG === country?.id) {
        isSingaporeUser.value = true;
      } else if (USER_COUNTRY.MY === country?.id) {
        isMalaysianUser.value = true;
      } else {
        isSingaporeUser.value = false;
        isMalaysianUser.value = false;
      }
    };
    onMounted(async () => {
      await checkUserCountry();
    });
    return {
      labelOtherPayment,
      labelDigitalPayment,
      digitalCardIdSelected,
      digitalPaymentMethods,
      otherPaymentMethods,
      paymentType,
      onSelectType,
      cardOutline,
      addCircleOutline,
      onSelectDigitalCard,
      isSingaporeUser,
      isMalaysianUser
    };
  },
  watch: {
    paymentType() {
      this.$emit(
        'onChangePaymentType',
        this.isFullyPaidCustBalance ? INVOICE_PAYMENT_TYPE.CUSTOMER_CREDIT : this.paymentType,
        this.digitalCardIdSelected
      );
    },
    digitalCardIdSelected() {
      this.$emit(
        'onChangePaymentType',
        this.isFullyPaidCustBalance ? INVOICE_PAYMENT_TYPE.CUSTOMER_CREDIT : this.paymentType,
        this.digitalCardIdSelected
      );
    },
    listCards: {
      handler() {
        const { CREDIT_CARD, PAYNOW } = INVOICE_PAYMENT_TYPE;
        // const paynow = {
        //   id: 99,
        //   brand: 'Paynow'
        // };
        // const mapDigitalCard = this.isSgUserOnly ? [...this.listCards, paynow] : [...this.listCards];
        const mapDigitalCard = [...this.listCards]; // TODO temporary remove paynow payment method per finance team request
        this.digitalPaymentMethods = mapDigitalCard.map((item, idx) => {
          return {
            id: item.id,
            name: item.brand,
            image: CARD_IMAGE_BRAND[item.brand.toUpperCase()],
            ...(item.id !== 99 ? { value: CREDIT_CARD } : { value: PAYNOW }),
            ...(item.id !== 99 && { exp: `*${item.last4} ${item.exp_month}/${item.exp_year}` }),
            isDefault: idx === 0
          };
        });
      },
      deep: true
    }
  }
});
</script>
<style lang="scss" scoped>
.label {
  font-weight: 600;
}
ion-img {
  width: 2rem;
}
.flex-row {
  flex-direction: row;
}
.form-text {
  & > div {
    width: 100%;
    & > .form-card {
      width: 100%;
      display: flex;
      padding: 15px 0px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    }
  }
}
ion-icon {
  font-size: 25px;
  float: right;
}
.card-image {
  width: 40px;
  height: auto;
}
.card-badge {
  color: white;
  background: #04565a;
  padding: 2px 4px;
  font-size: 10px;
  border-radius: 7px;
  opacity: 0.9;
}
.underline {
  border-bottom: 1px solid #cfcfcf;
}

.line {
  width: 100%;
  height: 6px;
  background: #eeeded;
}
.bg-gray {
  background: #f3f3f3;
  box-shadow: none;
  border: 1px solid #eeeded;
}
.flex-basis-90 {
  flex-basis: 90%;
}
.f-white {
  color: #fff;
}
.bor-15 {
  border-radius: 15px;
}
.w-content {
  width: fit-content;
}
</style>
