<template>
  <ion-page>
    <ion-header>
      <ion-toolbar class="toolbar-header">
        <ion-icon
          @click="goBack"
          size="large"
          color="primary"
          slot="start"
          :icon="chevronBackOutline"
        ></ion-icon>
        <ion-label class="fw-600 fs-3">{{ $t('select_payment') }}</ion-label>
      </ion-toolbar>
    </ion-header>
    <skeleton-select-payment v-if="loadingInit" />
    <ion-content v-else>
      <ion-grid class="mb-2">
        <ion-row class="ion-align-items-center">
          <ion-col>
            <ion-row>
              <div class="d-flex align-center ml-1">
                <ion-icon class="i-help" :icon="helpCircleOutline"></ion-icon>
                <ion-label class="fs-2 ml-1 fw-500 text-gray-700">
                  {{ $t('use_available_credits') }}
                </ion-label>
              </div>
            </ion-row>
            <ion-row>
              <ion-label
                class="ml-6 mt-1 fw-500"
                :class="{
                  'text-primary-orange-500': remainingCredits <= 0,
                  'text-primary-green-500': remainingCredits > 0
                }"
                >{{ $t('remaining_credits') }}
                {{ priceFormatter(currencySymbol, remainingCredits) }}</ion-label
              >
            </ion-row>
          </ion-col>
          <ion-col size="auto">
            <ion-toggle ref="toggleRef" v-model="isChecked" :disabled="!availableCreditAmount"></ion-toggle>
          </ion-col>
        </ion-row>
      </ion-grid>
      <div class="spacer"></div>
      <card-payment
        ref="cardPaymentRef"
        @onChangePaymentType="onPaymentSelected"
        @setOpenModalAddCard="setOpenModalAddCard(true)"
        @addNetsBankCard="addNetsBankCard"
        :listCards="listCardSortDefault"
        :isOpenFormNetsPayment="isOpenFormNetsPayment"
        :isFullyPaidCustBalance="isFullyPaidCustBalance"
      />
      <div class="spacer"></div>
      <comp-summary-info
        :selectedInvoices="selectedInvoicesData"
        :currencySymbol="currencySymbol"
        :customerCreditAllocation="customerCreditAllocation"
        :totalInvoicesAmount="totalInvoicesAmount"
        :grandTotal="grandTotal"
        :totalPaidAmount="totalPaidAmount"
      />
      <div class="spacer"></div>
    </ion-content>

    <skeleton-bottom v-if="loadingInit" />
    <comp-bottom-content
      v-else
      @handlePayInvoices="enterPayment"
      :totalPaidAmount="totalPaidAmount"
      :currencySymbol="currencySymbol"
      :paymentType="paymentType"
      :isDigitalPayment="isDigitalPayment"
      :isFullyPaidCustBalance="isFullyPaidCustBalance"
    />
    <modal-input-credits
      @close-modal="closeModalInputCredit"
      @onUseCreditBalance="onUseCreditBalance"
      :isShowInputCredit="isShowInputCredit"
      :currencySymbol="currencySymbol"
      :initialCredit="initialCreditValue"
      :remainingCredits="availableCreditAmount"
    />
    <ion-modal
      :is-open="isOpenAddCardPayment"
      mode="ios"
      css-class="modal-add-card"
      @didDismiss="setOpenModalAddCard(false)"
    >
      <modal-add-card
        :isEdit="false"
        :infoCard="null"
        :isNetCard="listNetsCard.length > 0"
        :isDefaultCard="false"
        @add-card="addDigitalCard"
        @close-modal="setOpenModalAddCard(false)"
      ></modal-add-card>
    </ion-modal>
    <ion-modal mode="ios" :is-open="isDigitalPaymentSummaryOpen" :backdropDismiss="false">
      <modal-digital-payment-summary
        @closeModal="setDigitalPaymentSummaryOpen(false)"
        @viewQrCode="onRedirectWebhook"
        @cancelPaynowPayment="cancelPaynowPayment"
        @backToInvoice="backToInvoice"
        @backToHome="backToHome"
        :currencySymbol="currencySymbol"
        :paymentSummary="paymentSummary"
        :invoices="selectedInvoicesData"
        :paymentType="paymentType"
        :appliedCredit="customerCreditAllocation"
        :grandTotal="grandTotal"
        :totalPaidAmount="totalPaidAmount"
      />
    </ion-modal>

    <ion-loading
      :is-open="isShowBackdropLoading || isLoadingCancellingPayment"
      cssClass="custom-loading"
      message=""
      spinner="crescent"
    >
    </ion-loading>
  </ion-page>
</template>
<script>
// packages
import { addStripeCard } from '@/modules/b2b/services/graphql';
import { ACTIONS } from '@/modules/b2b/store/payment/actions';
import ModalAddCard from '@/modules/b2b/views/account/Account/components/partials/ModalAddCard.vue';
import { INVOICE_PAYMENT_METHOD, INVOICE_PAYMENT_TYPE, TOAST_COLOR } from '@/modules/shared/constants/';
import { openToast, useLoading } from '@/modules/shared/utils/';
import handleCallApi from '@/modules/shared/utils/services.js';
// import Payment from '@/plugins/nets-payment-plugin.js';
import { toastError, useAlert } from '@/modules/shared/utils';
import { useConfig, useGetStorageData } from '@/usecases/';
import { priceFormatter } from '@/utils/';
import { Capacitor } from '@capacitor/core';
import { Device } from '@capacitor/device';
import { getPlatforms, isPlatform, useBackButton } from '@ionic/vue';
import { chevronBackOutline, helpCircleOutline } from 'ionicons/icons';
import { computed, defineComponent, ref, watch } from 'vue';
import { createNamespacedHelpers } from 'vuex';

// components
import {
  ModalInputCredits,
  SkeletonBottom,
  SkeletonSelectPayment
} from '@/modules/shared/components/invoices-payment';
import { Browser } from '@capacitor/browser';
import { CardPayment, CompBottomContent, CompSummaryInfo, ModalDigitalPaymentSummary } from './components';

const { mapGetters, mapActions } = createNamespacedHelpers('b2b/payment');

export default defineComponent({
  name: 'select-payment-b2b',
  inject: ['$storage'],
  components: {
    CompBottomContent,
    CompSummaryInfo,
    CardPayment,
    ModalInputCredits,
    ModalAddCard,
    SkeletonBottom,
    SkeletonSelectPayment,
    ModalDigitalPaymentSummary
  },
  setup() {
    const { router, store, t } = useConfig();
    const { selectedUser, selectedCompany } = useGetStorageData();
    const { createLoading, dismissLoading } = useLoading();
    const { createAlert, createAlertTwoAction } = useAlert();
    const isOpenAddCardPayment = ref(false);
    const isShowInputCredit = ref(false);
    const isChecked = ref(false);
    const isDigitalPaymentSummaryOpen = ref(false);
    const isShowBackdropLoading = ref(false);
    const loadingInit = ref(false);
    const isMobileNative = ref(true);
    const setOpenLoadingInit = (state) => {
      loadingInit.value = state;
    };
    const setShowBackdropLoading = (state) => {
      isShowBackdropLoading.value = state;
    };
    const setDigitalPaymentSummaryOpen = (state) => {
      isDigitalPaymentSummaryOpen.value = state;
    };
    const goBack = () => {
      router.back();
    };
    const setOpenModalAddCard = (value) => {
      isOpenAddCardPayment.value = value;
    };
    const backToInvoice = () => {
      setDigitalPaymentSummaryOpen(false);
      router.replace('/b2b/invoices/select-invoices');
    };
    const backToHome = () => {
      setDigitalPaymentSummaryOpen(false);
      router.replace('/b2b/main/home');
    };
    useBackButton(10, () => {
      goBack();
    });
    const genericError = computed(() => store.getters[`b2b/payment/error`]);

    const validateCustomerData = async () => {
      const { id, name, address } = await selectedCompany.value;
      if (!id || !name || !address) {
        createAlert(
          t('missing_customers_data_label'),
          t('missing_customers_data_desc'),
          () => router.replace('/b2b/select-customer'),
          t('selectCompany.select_company')
        );
        return;
      }
    };
    watch(genericError, () => {
      if (genericError.value) {
        createAlert(t('something_went_wrong_please_try_again'), genericError.value.message, () =>
          backToHome()
        );
      }
    });

    return {
      goBack,
      backToHome,
      backToInvoice,
      chevronBackOutline,
      isShowInputCredit,
      helpCircleOutline,
      isChecked,
      isMobileNative,
      isDigitalPaymentSummaryOpen,
      setDigitalPaymentSummaryOpen,
      priceFormatter,
      INVOICE_PAYMENT_TYPE,
      setOpenModalAddCard,
      isOpenAddCardPayment,
      isShowBackdropLoading,
      setShowBackdropLoading,
      createLoading,
      dismissLoading,
      customerCreditAllocation: ref(0),
      totalOverpayment: ref(0),
      totalOverdue: ref(0),
      totalPaidAmount: ref(0),
      selectedInvoicesData: ref([]),
      paymentType: ref(INVOICE_PAYMENT_TYPE.CREDIT_CARD),
      deviceId: ref(''),
      loadingInit,
      isOpenFormNetsPayment: ref(false),
      digitalCardIdSelected: ref(null),
      setOpenLoadingInit,
      isFullyPaidCustBalance: ref(false),
      remainingCredits: ref(0),
      listCardSortDefault: ref([]),
      enterExternalUrl: ref(false),
      toastError,
      createAlert,
      createAlertTwoAction,
      selectedUser,
      selectedCompany,
      validateCustomerData
    };
  },
  watch: {
    customerCreditAllocation(value) {
      this.updateRemainingBalance(value);
      if (value > 0 && !this.paymentType) {
        this.paymentType = INVOICE_PAYMENT_TYPE.CUSTOMER_CREDIT;
      }
      if (value === +this.totalInvoicesAmount.toFixed(2)) {
        this.isFullyPaidCustBalance = true;
        this.paymentType = INVOICE_PAYMENT_TYPE.CUSTOMER_CREDIT;
        this.$refs.cardPaymentRef.paymentType = INVOICE_PAYMENT_TYPE.CUSTOMER_CREDIT;
        this.$refs.cardPaymentRef.digitalCardIdSelected = null;
      } else {
        this.isFullyPaidCustBalance = false;
      }
    },
    isChecked(value) {
      if (value) {
        this.isShowInputCredit = true;
      } else {
        this.isShowInputCredit = false;
        if (this.customerCreditAllocation > 0) {
          this.customerCreditAllocation = 0;
        }
      }
    },
    selectedInvoicesData(value) {
      this.customerCreditAllocation = 0;
      this.$nextTick(() => {
        if (this.$refs.toggleRef) {
          this.$refs.toggleRef.$el.checked = false;
        }
      });
      this.selectedInvoicesData = value;
    },
    totalPaidAmount(value) {
      this.totalPaidAmount = Number(value);
    },
    paymentType(value) {
      if (this.isDigitalPayment) {
        this.totalPaidAmount = this.grandTotal;
      }
      this.paymentType = value;
    }
  },
  ionViewDidEnter() {
    if (!Capacitor.isNativePlatform() && this.isEnterStripeUrl) {
      this.$router.push(`/b2b/payment-details/${this.paymentType}`);
    }

    this.resetCompState();
  },
  async ionViewDidLeave() {
    await this.$storage.removeCountDownTime();
  },
  async mounted() {
    this.setOpenLoadingInit(true);
    this.deviceId = (await Device.getId()).uuid;
    this.isMobileNative = Capacitor.isNativePlatform();
    await this.handleGetCard();
  },
  computed: {
    ...mapGetters([
      'selectedPaymentInvoices',
      'cardList',
      'defaultCardId',
      'loading',
      'error',
      'status',
      'listNetsCard',
      'paymentSummary',
      'isLoadingCancellingPayment',
      'isEnterStripeUrl'
    ]),
    selectedInvoices() {
      return this.selectedPaymentInvoices?.selectedInvoices ?? [];
    },
    totalInvoicesAmount() {
      return this.selectedPaymentInvoices?.totalInvoiceAmount;
    },
    currencySymbol() {
      return this.selectedPaymentInvoices?.selectedInvoices[0]?.currencySymbol ?? 'S$';
    },
    availableCreditAmount() {
      return +this.selectedPaymentInvoices?.availableCreditAmount;
    },
    initialCreditValue() {
      if (this.availableCreditAmount > this.totalInvoicesAmount) {
        return this.totalInvoicesAmount;
      } else {
        return this.selectedPaymentInvoices?.availableCreditAmount - this.customerCreditAllocation;
      }
    },
    grandTotal() {
      return +this.totalInvoicesAmount.toFixed(2) - this.customerCreditAllocation;
    },
    isDigitalPayment() {
      return INVOICE_PAYMENT_METHOD.DIGITAL.includes(this.paymentType);
    },
    isPaynowSelected() {
      return this.paymentType === INVOICE_PAYMENT_TYPE.PAYNOW;
    }
  },
  methods: {
    ...mapActions([
      ACTIONS.GET_LIST_CUSTOMER_CARD,
      ACTIONS.GET_LIST_NETS_CARD,
      ACTIONS.GET_NETS_MERCHANT_TOKEN,
      ACTIONS.GET_DEFAULT_CARD,
      ACTIONS.SET_DEFAULT_CARD,
      ACTIONS.ADD_STRIPE_CARD,
      ACTIONS.SET_SELECTED_PAYMENT_INVOICES,
      ACTIONS.CREATE_AUTOMATIC_BATCH_PAYMENTS,
      ACTIONS.CANCEL_PREPAYMENT,
      ACTIONS.SET_IS_ENTER_STRIPE_URL
    ]),
    closeModalInputCredit() {
      this.isShowInputCredit = false;
      if (!this.customerCreditAllocation) {
        this.remainingCredits = this.availableCreditAmount;
        this.isChecked = false;
      } else {
        this.isChecked = true;
      }
    },
    resetCompState() {
      this.remainingCredits = this.availableCreditAmount;
      this.totalPaidAmount = this.grandTotal;
      this.selectedInvoicesData = this.selectedInvoices;
      this.paymentType = null;
      this.$nextTick(() => {
        if (this.$refs.cardPaymentRef) {
          this.$refs.cardPaymentRef.paymentType = null;
          this.$refs.cardPaymentRef.digitalCardIdSelected = null;
        }
        if (this.$refs.toggleRef) {
          this.$refs.toggleRef.$el.checked = false;
        }
      });
    },
    onUseCreditBalance(amount) {
      if (+amount > this.remainingCredits) {
        this.toastError(this.$t('input_amount_error'));
        return;
      } else if (+amount > +this.grandTotal.toFixed(2)) {
        this.toastError(this.$t('input_amount_error_2'));
        return;
      } else if (+amount < 0) {
        this.toastError(this.$t('invalid_amount_negative'));
        return;
      } else {
        this.customerCreditAllocation = +amount;
      }
    },
    onPaymentSelected(paymentType, digitalCardIdSelected) {
      this.paymentType = paymentType;
      this.digitalCardIdSelected = digitalCardIdSelected;
    },
    updateRemainingBalance(value) {
      this.remainingCredits = this.availableCreditAmount - value;
      this.totalPaidAmount = this.grandTotal;
    },

    async addDigitalCard(card, isDefault) {
      this.setShowBackdropLoading(true);
      await this.validateCustomerData();
      const { id: customerId } = await this.selectedCompany;
      const custId = { customerId };
      const params = { ...card, ...custId };
      const payload = {
        isQuery: false,
        services: addStripeCard,
        variables: params
      };
      let toastMessage;
      try {
        const data = await handleCallApi(payload);
        const err = data?.addCardByCustomer?.type ?? false;
        if (err) {
          toastMessage = {
            message: data?.addCardByCustomer?.message ?? '',
            color: TOAST_COLOR.DANGER
          };
        } else {
          toastMessage = {
            color: TOAST_COLOR.BLACK,
            message: this.$t('accountPage.added_card_successfully')
          };
        }
        if (isDefault)
          await this[ACTIONS.SET_DEFAULT_CARD]({
            cardId: data.addCardByCustomer.cardId,
            customerId: customerId
          });
        await this.handleGetCard();
      } catch (e) {
        toastMessage = {
          message: e,
          color: TOAST_COLOR.DANGER
        };
      } finally {
        this.setShowBackdropLoading(false);
        this.setOpenModalAddCard(false);
        await openToast(toastMessage);
      }
    },
    async addNetsBankCard() {
      // let errorMessage = '';
      // try {
      //   const data = await Payment.onRegistration({
      //     mid: process.env.VUE_APP_NETS_MERCHANT_ID,
      //     muid: `${this.activeUser.id}`
      //   });
      //   if (data.isSuccess) {
      //     const res = await this[ACTIONS.GET_NETS_MERCHANT_TOKEN]({
      //       t0102: data.result,
      //       deviceId: this.deviceId
      //     });
      //     if (res) {
      //       await this[ACTIONS.GET_LIST_NETS_CARD](this.deviceId);
      //       this.isOpenFormNetsPayment = !this.listNetsCard || this.listNetsCard.length === 0;
      //     } else {
      //       errorMessage = this.error;
      //     }
      //   } else {
      //     errorMessage = data.result;
      //   }
      //   if (errorMessage) {
      //     const alert = await alertController.create({
      //       header: this.$t('nets_payment_error'),
      //       message: errorMessage,
      //       buttons: [this.$t('close')]
      //     });
      //     await alert.present();
      //   }
      // } catch (error) {
      //   const alert = await alertController.create({
      //     header: this.$t('on_registration'),
      //     message: `ERROR ${JSON.stringify(error)}`,
      //     buttons: [this.$t('close')]
      //   });
      //   await alert.present();
      // }
    },
    async handleGetCard() {
      await this.validateCustomerData();
      const { id: customerId } = await this.selectedCompany;
      Promise.allSettled([
        // this[ACTIONS.GET_LIST_NETS_CARD](this.deviceId),
        this[ACTIONS.GET_LIST_CUSTOMER_CARD](customerId),
        this[ACTIONS.GET_DEFAULT_CARD](customerId)
      ]).then(() => {
        if (!this.listNetsCard || this.listNetsCard.length < 1) {
          this.isOpenFormNetsPayment = false;
        }
        let listCardSort = [...this.cardList];
        const defaultCard = listCardSort.find((item) => item.id === this.defaultCardId);
        if (defaultCard) {
          listCardSort = listCardSort.filter((item) => item.id !== this.defaultCardId);
          listCardSort.unshift(defaultCard);
        }
        this.listCardSortDefault = listCardSort;
        this.setOpenLoadingInit(false);
      });
    },
    async enterPayment() {
      await this.validateCustomerData();

      if (this.isDigitalPayment || this.isFullyPaidCustBalance) {
        this.setShowBackdropLoading(true);
        await this.submitDigitalPayment();
        if (this.error) {
          this.setShowBackdropLoading(false);
          this.createAlert(
            this.$t('payment_creation_failed'),
            this.error?.message,
            this.backToInvoice,
            this.$t('OK')
          );
        } else {
          const is3dSecure = this.paymentSummary?.redirectUrl;
          const onActionPaynow = async () => {
            await this.onRedirectWebhook();
          };
          if (is3dSecure) {
            this.setShowBackdropLoading(false);
            this.onRedirectWebhook();
          } else if (this.isPaynowSelected) {
            this.setShowBackdropLoading(false);
            this.createAlert(this.$t('orderB2b.paynowInstruction'), '', onActionPaynow, this.$t('OK'));
          } else {
            this.setShowBackdropLoading(false);
            this.setDigitalPaymentSummaryOpen(true);
          }
        }
      } else {
        await this.submitOtherPayment();
      }
    },
    async submitDigitalPayment() {
      let invoiceIDs = [];
      let debitNoteIDs = [];
      for (const invoice of this.selectedInvoices) {
        if (invoice.debitNoteId) {
          debitNoteIDs.push(invoice.debitNoteId);
        } else {
          invoiceIDs.push(invoice.invoiceId);
        }
      }
      const paramsCreatePayment = {
        invoiceIDs,
        debitNoteIDs,
        paymentTypeId: this.isFullyPaidCustBalance ? INVOICE_PAYMENT_TYPE.CUSTOMER_CREDIT : this.paymentType,
        totalPaidAmount: parseFloat(this.totalPaidAmount.toFixed(2)),
        balanceAllocationAmount: this.customerCreditAllocation,
        stripeCardId:
          this.isPaynowSelected || this.isFullyPaidCustBalance || this.paymentType === 7
            ? null
            : this.digitalCardIdSelected,
        stripeCustomerId: null,
        isMobile: this.isMobileNative
      };
      await this[ACTIONS.CREATE_AUTOMATIC_BATCH_PAYMENTS]({ paramsCreatePayment });
    },
    async submitOtherPayment() {
      await this[ACTIONS.SET_SELECTED_PAYMENT_INVOICES]({
        selectedInvoices: this.selectedInvoices,
        totalInvoiceAmount: +this.totalInvoicesAmount.toFixed(2),
        availableCreditAmount: +this.availableCreditAmount.toFixed(2),
        overdueAmount: +this.totalOverdue.toFixed(2),
        overPayment: +this.totalOverpayment.toFixed(2),
        totalPaidAmount: +this.totalPaidAmount.toFixed(2),
        customerCreditAllocation: +this.customerCreditAllocation.toFixed(2)
      });
      this.$router.push({
        path: '/b2b/invoices/payment-details/',
        query: {
          'payment-type': this.paymentType
        }
      });
    },
    async onRedirectWebhook() {
      if (
        getPlatforms().includes('mobileweb' || 'pwa' || 'desktop') ||
        (!isPlatform('ios') && !isPlatform('android'))
      ) {
        Browser.open({ url: this.paymentSummary?.redirectUrl, windowName: '_self' });
        await this[ACTIONS.SET_IS_ENTER_STRIPE_URL](true);
      } else if (isPlatform('ios') || isPlatform('android')) {
        await this.$browser.open({
          toolbarColor: '#fff',
          presentationStyle: 'fullscreen',
          url: this.paymentSummary?.redirectUrl
        });
        await this.$browser.addListener('browserFinished', async () => {
          await this.setOpenLoadingInit(true);
          await this.$router.replace(`/b2b/payment-details/${this.paymentType}`);
          this.$browser.removeAllListeners();
        });
      }
    },
    async onCancelPaynow() {
      const closeAction = () => {
        this.setDigitalPaymentSummaryOpen(false);
        this.$router.replace('/b2b/invoices/select-invoices');
      };
      await this[ACTIONS.CANCEL_PREPAYMENT]({ stripeCheckoutSessionId: this.paymentSummary.id });
      if (!this.isLoadingCancellingPayment && !this.error) {
        closeAction();
      } else if (!this.isLoadingCancellingPayment && this.error) {
        this.createAlert('', this.error.message, closeAction);
      }
    },
    cancelPaynowPayment() {
      this.createAlertTwoAction(
        this.$t('cancel_payment'),
        this.$t('cancel_payment_desc'),
        this.$t('orderB2b.cancelConfirmButton'),
        this.onCancelPaynow,
        this.$t('orderB2b.cancelStayButton')
      );
    }
  }
});
</script>

<style lang="scss" scoped>
.i-help {
  color: #eb8c31;
  font-size: 22px;
}
.bor-15 {
  --border-radius: 15px;
}
ion-toggle {
  height: 32px;
  width: 50px;
  --handle-background: #fff;
  --handle-background-checked: #fff;
  --background-checked: #00676a;
  --handle-width: 27px;
  --handle-height: 40px;
  --handle-border-radius: 16px;
  --handle-max-height: 28px;
  --handle-spacing: 2px;
  /* Required for iOS handle to overflow the height of the track */
  overflow: visible;
  contain: none;
}
</style>
